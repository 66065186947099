.support-container {
    background-color: #f4f4f4;
    padding: 20px;
    text-align: center;
    max-width: 800px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .support-container h1 {
    font-size: 24px;
    color: #333;
  }
  
  .support-container h2 {
    font-size: 20px;
    color: #333;
    margin-top: 20px;
  }
  
  .support-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .support-container ul li {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
    margin-bottom: 10px;
  }
  
  .support-container ul ul li {
    margin-left: 20px;
  }
  
  .support-container a {
    color: #007bff;
    text-decoration: none;
  }
  