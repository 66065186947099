.aboutUs-container {
    background-color: #f4f4f4;
    padding: 20px;
    text-align: center;
    max-width: 800px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .aboutUs-container h1 {
    font-size: 24px;
    color: #333;
  }
  
  .aboutUs-container p {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
    margin-bottom: 20px;
  }
  