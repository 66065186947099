.content-profile {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
}

.message .tri-ligne {
    border: 2px solid #4F4294;
}

.message {
    width: 50%;
    border: 1px solid #ced4da;
    border-radius: 10px;
}

.profile {
    border: 1px solid #ced4da;
    width: 30%;
    border-radius: 10px;
}

.profile h1,
.profile p {
    text-align: center;
}

.profile hr {
    margin: 20px;
    font-size: 2rem;
}

.profile span i,
.profile h3 {
    margin-left: 20px;
}

.profile span i {
    color: #4F4294;
    margin-right: 10px;
}

.img-profile {
    background-color: #EC634E;
    padding-top: 20px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

}

@media screen and (max-width: 768px) {
    .content-profile {
        flex-direction: column;
        align-items: center;
    }

    .message,
    .profile {
        width: 100%;
        margin: 10px 0;
    }
}