.rating {
    margin-bottom: 20px;
    width: 50%;
    margin: auto;
}

.comment {
    margin-top: 20px;
}

textarea {
    width: 100%;
    height: 100px;
}

.comments {
    margin-top: 20px;
}

.comments ul {
    list-style-type: none;
    padding: 0;
}

.comments li {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 10px;
}

.modal-content {
    color: white;
    margin: auto;
    background-color: #4F4294;
    width: 70%;
}
.sumau{
    background-color: #EC634E;
}