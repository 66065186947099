/* Google Fonts - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
*/
.page-login {
  font-family: "Poppins", sans-serif;
  background-color: #EC634E;
}

.logoChaye {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 30px;
}

/*======= H ========*/
h2 {
  color: #1f1f1f;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.5px;
}

.forgot-pass {
  font-family: "Poppins", sans-serif;
  color: #ec634e !important;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.serappeller {
  font-family: "Poppins", sans-serif;
  color: #949090 !important;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btnValider {
  display: flex;
  width: 170px !important;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50px !important;
  background: #4F4294 !important;
  box-shadow: 2px 1px 8px 0px rgba(65, 28, 13, 0.20);
}

.btnValider:hover {
  background: #EC634E !important;
}

.container-login {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* column-gap: 30px; */
  margin: 0 auto;
  background-color: #EC634E;
}

.form {
  max-width: 430px;
  text-align: center;
  width: 100%;
  padding: 42px;
  border-radius: 6px;
  border-radius: 32px;
  background: #FFF;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.10);
}

/*
.form.signup {
  opacity: 0;
  pointer-events: none;
}

.forms.show-signup .form.signup {
  opacity: 1;
  pointer-events: auto;
}

.forms.show-signup .form.login {
  opacity: 0;
  pointer-events: none;
}
*/

header {
  font-size: 28px;
  font-weight: 600;
  color: #232836;
  text-align: center;
}

form {
  margin-top: 30px;
}

.form .field {
  position: relative;
  height: 50px;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
}

.field input,
.field button {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 16px;
  font-weight: 400;
  border-radius: 6px;
}

.field input {
  outline: none;
  padding: 0 15px;
  border: 1px solid#CACACA;
}

.field input:focus {
  border-bottom-width: 2px;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #8b8b8b;
  cursor: pointer;
  padding: 5px;
}

.field button {
  color: #fff;
  background-color: #0171d3;
  transition: all 0.3s ease;
  cursor: pointer;
}

.field button:hover {
  background-color: #016dcb;
}

.form-link {
  text-align: center;
  margin-top: 10px;
}

.form-link span,
.form-link a {
  font-size: 14px;
  font-weight: 400;
  color: #232836;
}

.form a {
  color: #EC634E;
  text-decoration: none;
}

.form-content a:hover {
  text-decoration: underline;
}

.line {
  position: relative;
  height: 1px;
  width: 100%;
  margin: 36px 0;
  background-color: #d4d4d4;
}

.line::before {
  content: "Ou inscrivez-vous en utilisant";
  position: absolute;
  right: -27%;
  left: 49%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #8b8b8b;
  padding: 0 15px;
}

.media-options a {
  display: flex;
  align-items: center;
  justify-content: center;
}

a.facebook {
  color: #fff;
  background-color: #4267b2;
}

a.facebook .facebook-icon {
  height: 28px;
  width: 28px;
  color: #0171d3;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

a.apple {
  color: #fff;
  background-color: #090a0a;
}

a.apple .apple-icon {

  color: #fff;
  font-size: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 15px !important;

}

.facebook-icon,
.apple-icon,
img.google-img {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

img.google-img {
  height: 20px;
  width: 20px;
  object-fit: cover;
}

a.google {
  border: 1px solid #cacaca;
}

a.google span {
  font-weight: 500;
  opacity: 0.6;
  color: #232836;
}

@media screen and (max-width: 400px) {
  .form {
    padding: 20px 10px;
  }
}